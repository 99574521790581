.ant-drawer-header{
background:#ffd6e7 !important;
}

.ant-drawer-body{
background: #fff0f6 !important;
}

.ant-select-selector{
    border-radius: 0px!important;
}

