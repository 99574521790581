/* .reactEasyCrop_Contain {
  width: 100% !important;
  display: flex;
  justify-content: center;
  border: 1px solid black;
} */

.reactEasyCrop_Container {
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App {
  position: relative;
  width: 100% !important;
}

.crop-container {
  position: relative;
}

.controls {
  position: relative;
  top: 430px;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
}

.control-item {
  position: relative;
  top: 430px;

  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
}

.slider {
  padding: 22px 0px;
}

.zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 1px;
  border-top: 2px solid #a2a2da;
  border-bottom: 2px solid #a2a2da;
  background: #3f51b5;
  width: 100%;
}

.zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%;
}

.controls:hover input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}

/* Hide the default file input */
input[type="file"] {
  display: none;
}

/* Custom upload button styling */
.custom-file-upload {
  display: inline-block;
  border: 1px solid gray;
  cursor: pointer;
  border-radius: 5px;
  padding: 2px;
  font-size: small;
  user-select: none;
  transition: background-color 0.2s ease;
}

/* Ensure the modal content has a proper z-index */
.ant-modal {
  z-index: 1050 !important;
}

/* Optional: Style the zoom slider */
.zoom-slider {
  z-index: 1000;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 10px;
  color: #fff;
}
