@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto+Flex:opsz,wght@8..144,100..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap");
body {
  margin: 0px;
  box-sizing: border-box;
  padding: 0px;
}

.ant-upload-wrapper .ant-tooltip {
  display: none !important;
}
.ant-form-item-control {
  margin-top: -7px !important;
}

.ant-input {
  border-radius: 0px !important;
}

:where(.css-dev-only-do-not-override-1m70tvf).ant-picker-outlined {
  border-radius: 0px !important;
}
.ant-form-item-control-input-content {
  border-radius: 0px !important;
}

.leaflet-routing-container {
  display: none !important;
}

.ant-upload-list-item-name[title] {
  color: black !important;
}

:where(.css-dev-only-do-not-override-1m70tvf).ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  font-weight: bolder;
  font-size: 16px;
}

.mukta {
  font-family: "Mukta", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.apexcharts-title-text {
  font-family: "Roboto", sans-serif !important;
  font-size: 16px;
}
