@keyframes zoomBounce {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }

  50% {
    transform: scale(1.2);
    opacity: 1;
  }

  70% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

.zoom-text {
  border:1px solid black !important;
  display: flex;
  font-size: 24px;
  font-weight: bold;
  animation: zoomBounce 2s infinite;
}