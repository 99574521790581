/* 
.ant-form-item-explain-error{
    display: flex !important;
    width: 100%  !important;
    margin-bottom: 5px !important;
    font-size: small !important;
    justify-items: start !important;
    justify-content: start;
} */

#user_captcha_input_contact {
  border-radius: 0px;
}

.ant-form-item-explain-error {
  width: 100%;
  display: flex;
  justify-content: start;
}
