@keyframes zoom-in-out {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.zoom-text {
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  border-radius:'5px';
  padding: 2px;
  animation: zoom-in-out 2s infinite ease-in-out;
}
